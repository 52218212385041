<template>
	<div class="content-wrapper">
		<div class="content-container">
			<div id="recommended-region-create">
				<b-container fluid style="border: solid 1px #ebebeb;" class="pt-3 pb-3">
					<b-form :novalidate="true">
						<b-form-group class="pt-3 pb-3" style="border: solid 1px #ebebeb;">
							<label class="very-bold my-1 mlr-4"><i>지역명</i></label>
							<b-row class="my-1 mlr-4  pt-3" align-v="center">
								<b-col sm="4">
									<label class="bold" for="input-large">한글:</label>
								</b-col>
								<b-col sm="10">
									<b-form-input size="lg" v-model="formData.ko" placeholder="한글 지역명을 입력해주세요"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="my-1 mlr-4  pt-3" align-v="center">
								<b-col sm="4">
									<label class="bold" for="input-large">영문:</label>
								</b-col>
								<b-col sm="10">
									<b-form-input size="lg" v-model="formData.en" placeholder="영문 지역명을 입력해주세요"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="my-1 mlr-4  pt-3" align-v="center">
								<b-col sm="4">
									<label class="bold" for="input-large">간체:</label>
								</b-col>
								<b-col sm="10">
									<b-form-input size="lg" v-model="formData.cn" placeholder="중문 간체 지역명을 입력해주세요"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="my-1 mlr-4  pt-3" align-v="center">
								<b-col sm="4">
									<label class="bold" for="input-large">번체:</label>
								</b-col>
								<b-col sm="10">
									<b-form-input size="lg" v-model="formData.hk" placeholder="중문 번체 지역명을 입력해주세요"></b-form-input>
								</b-col>
							</b-row>
							<b-row class="my-1 mlr-4 pt-3" align-v="center">
								<b-col sm="4">
									<label class="bold" for="input-large">일어:</label>
								</b-col>
								<b-col sm="10">
									<b-form-input size="lg" v-model="formData.jp" placeholder="일어 지역명을 입력해주세요"></b-form-input>
								</b-col>
							</b-row>
							<label class="my-1 mr-4 f-right">지역명을 언어별로 입력해주세요.</label>
						</b-form-group>
						<b-form-group class="pt-3 pb-3" style="border: solid 1px #ebebeb;">
							<label class="very-bold my-1 mlr-4"><i>상위지역</i></label>
							<b-row class="my-1 mlr-4  pt-3" align-v="center">
								<b-col sm="4">
									<label class="bold" for="input-large">depth:</label>
								</b-col>
								<b-col sm="10">
									<b-form-select v-model="formData.parent_depth" :options="getAvailableDepth" />
								</b-col>
							</b-row>
							<b-row class="my-1 mlr-4 pt-3" align-v="center">
								<b-col sm="4">
									<label class="bold" for="input-large">지역 선택</label>
								</b-col>
								<b-col sm="10">
									<b-form-select v-model="formData.parent_area" :options="getPlausibleParent" />
								</b-col>
							</b-row>
							<label class="my-1 mr-4 f-right">상위 지역 정보를 입력해주세요.</label>
						</b-form-group>
						<b-form-group class="pt-3 pb-3" style="border: solid 1px #ebebeb;">
							<label class="very-bold my-1 mlr-4"><i>순번</i></label>
							<b-row class="my-1 mlr-4 pt-3" align-v="center">
								<b-col sm="10">
									<b-form-input v-model="formData.seq" size="lg" placeholder="순번 미입력 시, 제일 뒤에 추가합니다."></b-form-input>
								</b-col>
							</b-row>
							<label class="my-1 mr-4 f-right">순번을 입력해주세요.</label>
						</b-form-group>
						<div style="text-align:right;">
							<button @click="goToList" type="button" class="btn btn-danger" style="margin-right: 0.5rem;">취소</button>
							<button @click="onSubmit" type="button" class="btn btn-primary">저장</button>
						</div>
					</b-form>
				</b-container>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CreateRecommendedRegion',
	data() {
		return {
			formData: {
				ko: '',
				en: '',
				cn: '',
				hk: '',
				jp: '',
				parent_depth: null,
				parent_area: null,
				seq: '',
			},
			initialItems: '',
		};
	},
	created() {
		this.$store.dispatch('regions/list').then((res) => {
            this.$data.initialItems = res.items;
			if (this.$route.query.uid) {
				this.$store.dispatch('regions/get', { id: this.$route.query.uid }).then((res) => {
					let name = JSON.parse(res.name);
					this.formData.ko = name.ko ? name.ko : '';
					this.formData.en = name.en ? name.en : '';
					this.formData.cn = name.cn ? name.cn : '';
					this.formData.hk = name.hk ? name.hk : '';
					this.formData.jp = name.jp ? name.jp : '';

                    this.formData.parent_area = res.parent_id == '0' ? null : res.parent_id;
                    this.formData.parent_depth = this.$data.initialItems.find( item => item.uid == this.formData.parent_area).depth
					this.formData.seq = res.seq;
				});
            }
		});
	},
	computed: {
		getPlausibleParent() {
            let items = this.initialItems;
			let plausibleParents = [];
			let arrayOptions = [{ value: null, text: '지역명을 입력해주세요' }];
			if (items.length > 0) {
				plausibleParents = items.filter((o) => o.depth == this.formData.parent_depth);
				plausibleParents.forEach((o) => {
					arrayOptions.push({ value: o.uid, text: JSON.parse(o.name).ko });
				});
			}

			return arrayOptions;
		},
		getAvailableDepth() {
            let items = this.initialItems;
			let arrayOptions = [{ value: null, text: '상위 지역 depth 선택' }];
			if (items.length > 0) {
				let maxItem = items.reduce((acc, current) => {
					return acc.depth > current.depth ? acc : current;
                });
				let i;
				for (i = 1; i < maxItem.depth + 1; i++) {
					let option = {
						value: i,
						text: i,
					};
					arrayOptions.push(option);
				}
            }
			return arrayOptions;
		},
	},
	methods: {
		onSubmit() {
			if (this.$route.query.uid) {
				this.requestEdit()
					.then((res) => {
						this.goToList();
					})
					.catch((err) => {
						alert(err);
					});
			} else {
				this.requestCreate()
					.then((res) => {
						this.goToList();
					})
					.catch((err) => {
						alert(err);
					});
			}
		},
		goToList() {
			this.$router.push({ name: 'RecommendedRegionsList' });
		},
		requestList() {
			this.$store.dispatch('regions/list').then((res) => {
				return res.items;
			});
		},
		requestGet() {
			this.$store.dispatch('regions/get', { id: this.$route.query.uid }).then((res) => {});
		},
		requestEdit() {
			return new Promise((resolve, reject) => {
				let data = {
					parent_id: this.formData.parent_area ? this.formData.parent_area.toString() : '0',
					seq: this.formData.seq ? this.formData.seq.toString() : '',
					depth: this.formData.parent_depth ? (parseInt(this.formData.parent_depth) + 1).toString() : '1',
					name: JSON.stringify({
						en: this.formData.en ? this.formData.en : '',
						ko: this.formData.ko ? this.formData.ko : '',
						jp: this.formData.jp ? this.formData.jp : '',
						cn: this.formData.cn ? this.formData.cn : '',
						hk: this.formData.hk ? this.formData.hk : '',
					}),
				};
				this.$store
					.dispatch('regions/edit', { id: this.$route.query.uid, data: data })
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		requestCreate() {
			return new Promise((resolve, reject) => {
				let data = {
					parent_id: this.formData.parent_area ? this.formData.parent_area.toString() : '0',
					seq: this.formData.seq ? this.formData.seq.toString() : '',
					depth: this.formData.parent_depth ? (parseInt(this.formData.parent_depth) + 1).toString() : '1',
					name: JSON.stringify({
						en: this.formData.en ? this.formData.en : '',
						ko: this.formData.ko ? this.formData.ko : '',
						jp: this.formData.jp ? this.formData.jp : '',
						cn: this.formData.cn ? this.formData.cn : '',
						hk: this.formData.hk ? this.formData.hk : '',
					}),
                };
				this.$store
					.dispatch('regions/create', data)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.mlr-4 {
	margin-left: 2rem;
	margin-right: 2rem;
}
.very-bold {
	font-size: 1.5rem;
	font-weight: 900;
}
.bold {
	font-weight: 500;
}
.mr-5 {
	margin-right: 3rem;
}
.pt-3 {
	padding-top: 1rem !important;
}
.pb-3 {
	padding-bottom: 1rem !important;
}
.f-right {
	float: right;
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}
</style>
